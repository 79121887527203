import * as React from "react";
import * as ReactDOM from "react-dom";
import $ from "jquery";
import "./app.scss";
import { BrowserRouter } from "react-router-dom";
import App from "../components/app";
import { Provider } from "react-redux";
import { store } from "../state/store";
import MobileApp from "../mobileComponents/app";
import { EnvProvider } from "../components/envContext";

// Translations
import LocaleWrapper from "../wrappers/localeWrapper";
import PromotionsProvider from "../features/promotions/providers/promotionsProvider";
import { PusherProvider } from "../components/pusherContext";

document.addEventListener("DOMContentLoaded", () => {
  let appRootElement = document.getElementById("root") as HTMLDivElement;
  if (appRootElement) {
    let pusherId = appRootElement?.dataset.pusherId;

    ReactDOM.render(
      <EnvProvider envObject={root.dataset}>
        <PusherProvider pusherId={pusherId}>
          <Provider store={store}>
            <BrowserRouter>
              <LocaleWrapper>
                <PromotionsProvider>
                  {screen.width >= 768 ? <App /> : <MobileApp />}
                </PromotionsProvider>
              </LocaleWrapper>
            </BrowserRouter>
          </Provider>
        </PusherProvider>
      </EnvProvider>,
      appRootElement
    );
  }
});
